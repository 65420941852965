import { isEmpty } from './index'

export const required = value => {
  if (isEmpty(value)) {
    return 'This field is required'
  }

  return true
}

export const shouldUnique = (value, compareValue) => {
  if (value === compareValue) {
    return 'Material name and code should not be same.'
  }

  return true
}

export const requiredWithName = (name, value) => {
  if (isEmpty(value)) {
    return `${name} field is required`
  }

  return true
}
export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars'
  )
}

export const phoneValidator = phone => {
  if (isEmpty(phone)) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const regExp = /^\d{3}-\d{3}-\d{4}$/

  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(phone)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || 'Format must be XXX-XXX-XXXX'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (
    (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) ||
    `Enter number between ${min} and ${max}`
  )
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^((http|https):\/\/)(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length = 10) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The number of Character must be ${length}`
}
export const minLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= length || `The Min number of Character  must be ${length}`
}
export const maxLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= length || `The Max number of Character  must be ${length}`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}
export const minValue = (value, minVal = 1) => {
  if (isEmpty(value)) {
    return true
  }

  return value >= minVal || `The Value cann't be less than ${minVal}`
}

export const minDecimalValue = (value, minVal = 0.001) => {
  if (isEmpty(value)) {
    return true
  }

  return value >= minVal || `The Value cann't be less than ${minVal}`
}

export const shouldSameValue = (value, compareValue, errorText) => {
  if (value !== compareValue) {
    return errorText
  }

  return true
}