import {
  mdiVectorCircle,
  mdiLayers,
  mdiFormatListBulleted,
  mdiFileImage,
  mdiNoteText,
  mdiAttachment
 } from '@mdi/js'

export default {
  default: [
    {
      title: 'Projects',
      icon: mdiLayers,
      to: 'field-project',
    },
    {
      title: 'Form Templates',
      icon: mdiVectorCircle,
      to: 'form-templates',
    },
  ],
  more: [
    {
      title: 'Plans',
      icon: mdiLayers,
      to: 'field-plans',
    },
    {
      title: 'Specifications',
      icon: mdiVectorCircle,
      to: 'field-specifications'
    },
    {
      title: 'Tasks',
      icon: mdiFormatListBulleted,
      to: 'field-tasks'
    },
    {
      title: 'Photos',
      icon: mdiFileImage,
      to: 'field-photos'
    },
    {
      title: 'Forms',
      icon: mdiNoteText,
      to: 'field-forms'
    },
    {
      title: 'Files',
      icon: mdiAttachment,
      to: 'field-files'
    },
  ]
}

