/* eslint-disable radix */
// eslint-disable-next-line import/no-cycle
import api from '@/plugins/apiService'
import { storeActions } from '../../storeConstants'

const {
  GET_ALL_RECORDS,
  DELETE_BY_ID,
  GET_BY_ID,
  CREATE,
  UPDATE_BY_ID,
  UPDATE,
  UPDATE_FILE,
  IMPORT,
  DOWNLOAD,
  DELETE,
  CUSTOM_REQUEST,
} = storeActions

const customerActions = {
  // Export Data from DB
  [IMPORT]: (context, payLoad) => {
    api.setCustomHeaders('Authorization', context.rootState.auth.permissions.token)

    return api
      .findByParams(`${payLoad.component}/import`, { params: payLoad.params })
      .then(res => res)
  },
  // Export Data from DB

  // Create Data in DB
  [CREATE]: (context, payLoad) => {
    return api
      .save(`${payLoad.component}`, payLoad.object, { params: payLoad.filter })
      .then(res => res)
  },

  [DOWNLOAD]: (context, payLoad) => {
    return api
      .downlaod(`${payLoad.component}/${payLoad.objectId}`, { params: payLoad.object })
      .then(res => res)
  },

  // Load Data from DB
  [GET_ALL_RECORDS]: (context, payLoad) => {
    return api.findByParams(`${payLoad.component}`, { params: payLoad.filter }).then(res => res)
  },
  // Delete Data Using ID from DB
  [DELETE_BY_ID]: (context, payLoad) => {
    return api
      .delete(`${payLoad.component}/${payLoad.objectId}`, { params: payLoad.object })
      .then(res => res)
  },
  // Multiple Delete Data Using IDs from DB
  [DELETE]: (context, payLoad) => {
    return api.delete(`${payLoad.component}`, { data: payLoad.object }).then(res => res)
  },
  // Update Data Using ID
  [UPDATE_BY_ID]: (context, payLoad) => {
    return api.update(`${payLoad.component}/${payLoad.objectId}`, payLoad.object).then(res => res)
  },
  // Update
  [UPDATE]: (context, payLoad) => {
    return api.update(`${payLoad.component}`, payLoad.object, payLoad.config).then(res => res)
  },
  // UpdateFile
  [UPDATE_FILE]: (context, payLoad) => {
    api.deleteCustomHeaders('Authorization')
    api.setCustomHeaders('Content-Type', 'application/octet-stream')
    api.setCustomHeaders('x-ms-blob-type', 'BlockBlob')

    return api.update(`${payLoad.component}`, payLoad.file, payLoad.config).then(res => res)
  },
  // Get Data Using ID
  [GET_BY_ID]: (context, payload) => {
    return api.findAll(`${payload.component}/${payload.objectId}`).then(res => res)
  },
  // Load Data from DB
  [CUSTOM_REQUEST]: (context, payLoad) => {
    return api.findByParams(`${payLoad.component}`, payLoad.config).then(res => res)
  },
}

export default { ...customerActions }
