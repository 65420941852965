<template>
  <layout-content-vertical-nav :nav-menu-items="resolveMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive, launcherDrawer }">
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n></app-bar-i18n> -->
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->
           <v-btn icon @click="openDocumentModal">
            <v-icon>{{ icons.mdiTextBox }}</v-icon>
          </v-btn>
          <v-btn href="/support" icon>
            <v-icon>{{ icons.mdiFaceAgent }}</v-icon>
          </v-btn>
          <app-bar-notification :light="true"></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
          <app-launcher-button
            class="ml-3"
            :light="true"
            @openLauncher="launcherDrawer"
          ></app-launcher-button>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a href="https://themeselection.com" class="text-decoration-none">ThemeSelection</a
          ><span class="d-none d-md-inline">, All rights Reserved</span></span
        >
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon color="error" class="ms-2">
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>

    <!-- <template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template> -->
  </layout-content-vertical-nav>
</template>

<script>
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { getVuetify, useRouter } from '@core/utils'
import { mdiHeartOutline, mdiMenu, mdiFaceAgent, mdiArrowLeft, mdiTextBox } from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import navMenuItems from '@/navigation/vertical'
import navSetupItems from '@/navigation/set-up/SetupMenu'
import navMenuDriveItems from '@/navigation/work-drive'
import navSecurityMenuItems from '@/navigation/security'
import navFieldManagementMenu from '@/navigation/field-management'
import navGoalsMenuItems from '@/navigation/goals'
import navAccountingMenuItems from '@/navigation/accounting'
import navInventoryMenuItems from '@/navigation/inventory-management'
import navProjectMenuItems from '@/navigation/project-management'
import navTaskMenuItems from '@/navigation/task-management'
import navFormBuilderItems from '@/navigation/form-builder'
import navAdminConsoleMenuItems from '@/navigation/admin-console'
import navPartnerPortalMenuItems from '@/navigation/partner-portal'
import retailInventoryMenuItems from '@/navigation/retail-inventory'
import navManufacturingMenuItems from '@/navigation/manufacturing'
import store from '@/store'
import { storeGetters } from '@/store/storeConstants'
import { isAdmin } from '@/plugins/authService'
import AppLauncherButton from '../../../../@core/comp-functions/app-launcher/AppLauncherButton.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    // AppCustomizer,

    // App Bar Components
    // AppBarSearch,
    // AppBarI18n,
    // AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
    AppLauncherButton,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const $vuetify = getVuetify()
    const { route } = useRouter()
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    // resoving menu item according to selected APP

    const resolveMenuItems = computed(() => {
      const purchaseAppId = store.getters[`auth/${storeGetters.GET_PURCHASE_APP}`]
      const permissions = store.getters[`auth/${storeGetters.GET_ALL_PERMISSIONS}`]

      if (route.value.meta.app === 'work-drive') return navMenuDriveItems
      if (route.value.meta.app === 'set-up') {
        // remove menus which are not purchased
        const menus = navSetupItems.filter(opt => {
          return (
            !opt.appId ||
            opt.appId.some(id => {
              return purchaseAppId.includes(id)
            })
          )
        })

        // remove submenu which are not purchaased
        const finalMenus = menus.map(elm => {
          if (!elm.children) {
            return elm
          }

          return {
            ...elm,
            children: elm.children.filter(sub => {
              return (
                !sub.appId ||
                sub.appId.some(subId => {
                  return purchaseAppId.includes(subId)
                })
              )
            }),
          }
        })

        return finalMenus
      }
      if (route.value.meta.app === 'security') return navSecurityMenuItems
      if (route.value.meta.app === 'field-management') {
        return route.value.params.project_id
          ? navFieldManagementMenu.more
          : navFieldManagementMenu.default
      }
      if (route.value.meta.app === 'project-management') {
        return route.value.params.projectId
          ? [{
            title: route.value.params.projectNumber,
            icon: mdiArrowLeft,
            to: 'project-management',
          }, ...navProjectMenuItems.more]
          : navProjectMenuItems.default
      }
      if (route.value.meta.app === 'goal-management') return navGoalsMenuItems
      if (route.value.meta.app === 'manufacturing') return navManufacturingMenuItems
      if (route.value.meta.app === 'accounting') return navAccountingMenuItems
      if (route.value.meta.app === 'inventoryManagement') {
        if (isAdmin()) return navInventoryMenuItems
        const keys = Object.keys(permissions.inventoryManagement)
        const menus = navInventoryMenuItems.filter(
          opt => keys.includes(opt.module) || opt.subheader,
        )

        return menus
      }
      if (route.value.meta.app === 'taskManagement') return navTaskMenuItems
      if (route.value.meta.app === 'dashboards') return navTaskMenuItems
      if (route.value.meta.app === 'formBuilder') return navFormBuilderItems
      if (route.value.meta.app === 'adminConsole') return navAdminConsoleMenuItems
      if (route.value.meta.app === 'partnerPortal') return navPartnerPortalMenuItems
      if (route.value.meta.app === 'retailInventory') return retailInventoryMenuItems

      return navMenuItems
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return (
        appBarSearchQuery.value &&
        isMatched &&
        totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
      )
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive,
    ) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const openDocumentModal = () => {
      store.commit('documentation/SET_DOCUMENT_MODAL', true)
    }

    return {
      resolveMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      openDocumentModal,
      icons: {
        mdiMenu,
        mdiTextBox,
        mdiHeartOutline,
        mdiFaceAgent,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
