export default {
  namespaced: true,
  state: {
    folderIds: {
      Plans: '',
      Photos: '',
      Files: '',
      Specifications: ''
    },
  },
  getters: {
    GET_PLAN_FOLDER: state => state.folderIds.Plans,
    GET_PHOTOS_FOLDER: state => state.folderIds.Photos,
    GET_FILES_FOLDER: state => state.folderIds.Files,
    GET_SPECIFICATIONS_FOLDER: state => state.folderIds.Specifications,
  },
  mutations: {
    SET_FOLDER_IDS: (state, payload) => {
      state.folderIds = payload
    },
  },
  actions: {
    SET_FOLDER_IDS_STATE: ({ commit }, payload) => {
      commit('SET_FOLDER_IDS', payload)
    },
  },
}
