export default [
  {
    path: '/field-management/project',
    name: 'field-project',
    component: () => import('@/views/field-management/FieldProject.vue'),
    meta: {
      layout: 'content',
      app: 'field-management',
      appId: 14,
    },
  },
  {
    path: '/field-management/form-template',
    name: 'form-templates',
    component: () => import('@/views/field-management/FieldProject.vue'),
    meta: {
      layout: 'content',
      app: 'field-management',
      appId: 14,
    },
  },
  {
    path: '/field-management/project/:project_id/view',
    name: 'field-plans',
    component: () => import('@/views/field-management/FieldPlans.vue'),
    meta: {
      layout: 'content',
      app: 'field-management',
      appId: 14,
    },
  },
  {
    path: '/field-management/project/:project_id/pdf/:pdfId/:pageId',
    name: 'field-visualizer',
    component: () => import('@/views/field-management/FieldVisualizer.vue'),
    meta: {
      layout: 'content',
      app: 'field-management',
      appId: 14,
    },
  },
]
