import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'

const {
  GET_VISUAL_DATA,
  GET_VISUAL_COUNT,
  GET_LOADING,
  GET_PDF_PAGES,
  GET_COMMENTS,
  GET_ACTIVE_PAGEID,
  GET_ACTIVE_PAGENUMBER,
  GET_CURRENT_PAGE,
  GET_COMPARE_VISUAL_DATA,
  GET_OCR_STATUS,
  GET_BOOKMARK_STATUS,
} = storeGetters
const {
  SET_VISUAL_STATE,
  RESET_VISUAL_STATE,
  STATE_LOADING,
  SET_PDF_PAGES,
  RESET_PDF_PAGES,
  SET_COMMENTS,
  RESET_COMMENTS,
  SET_ACTIVE_PAGEID,
  SET_ACTIVE_PAGENUMBER,
  SET_COMPARE_VISUAL_DATA,
  SET_OCR_STATUS,
  SET_BOOKMARK_STATUS,
} = storeMutations
const { FETCH_VISUAL_DATA, FETCH_PDF_PAGES, FETCH_COMMENTS } = storeActions

export default {
  namespaced: true,
  state: {
    visualData: null,
    visualCount: 0,
    loading: false,
    pdfPages: [],
    pdfList: [],
    commentsData: [],
    activePageId: null,
    activePageNumber: null,
    compareVisualData: {},
    isOcrRun: false,
    estimationDetail: null,
    bookmarkStatus: false,
  },
  getters: {
    [GET_VISUAL_DATA]: state => state.visualData,
    [GET_OCR_STATUS]: state => state.isOcrRun,
    [GET_COMPARE_VISUAL_DATA]: state => state.compareVisualData,
    [GET_PDF_PAGES]: state => state.pdfPages,
    [GET_COMMENTS]: state => state.commentsData,
    [GET_VISUAL_COUNT]: state => state.visualCount,
    [GET_LOADING]: state => state.loading,
    [GET_ACTIVE_PAGEID]: state => state.activePageId,
    [GET_ACTIVE_PAGENUMBER]: state => state.activePageNumber,
    [GET_CURRENT_PAGE]: state => state.pdfPages.find(page => page.uuid === state.activePageId),
    [GET_BOOKMARK_STATUS]:state => state.bookmarkStatus,
    GET_ESTIMATION_DETAIL: state => state.estimationDetail,
    GET_PDF_LIST: state => state.pdfList,
  },
  mutations: {
    [RESET_VISUAL_STATE]: state => {
      state.visualData = null
      state.visualCount = 0
      state.compareVisualData = []
    },
    [SET_VISUAL_STATE]: (state, payload) => {
      state.visualData = payload.data
    },
    [STATE_LOADING]: (state, payload) => {
      state.loading = payload
    },
    [SET_PDF_PAGES]: (state, payload) => {
      state.pdfPages = payload
    },
    [RESET_PDF_PAGES]: state => {
      state.pdfPages = []
    },
    [SET_COMMENTS]: (state, payload) => {
      state.commentsData = payload
    },
    [RESET_COMMENTS]: state => {
      state.commentsData = []
    },
    [SET_ACTIVE_PAGEID]: (state, payload) => {
      state.activePageId = payload
    },
    [SET_ACTIVE_PAGENUMBER]: (state, payload) => {
      state.activePageNumber = payload
    },
    [SET_COMPARE_VISUAL_DATA]: (state, payload) => {
      state.compareVisualData[payload.version] = payload.data
    },
    [SET_OCR_STATUS]: (state, payload) => {
      state.isOcrRun = payload
    },
    [SET_BOOKMARK_STATUS]: (state, payload) => {
      state.bookmarkStatus = payload
    },
    SET_ESTIMATION_DETAIL: (state, payload) => {
      state.estimationDetail = payload
    },
    SET_PDF_LIST: (state, payload) => {
      state.pdfList = payload
    },
  },
  actions: {
    [FETCH_VISUAL_DATA]: ({ commit }, payload) => {
      return new Promise(resolve => {
        api
          .findByParams(`${payload.component}`, { params: payload.filter, cancelToken: payload.cancelToken })
          .then(({ data }) => {
            if (data.success && data.data) {
              if (payload.filter) {
                const commitPayload = data
                commitPayload.version = payload.filter.version
                commit(SET_COMPARE_VISUAL_DATA, commitPayload)

                return
              }
              commit(SET_VISUAL_STATE, data)
            }
          })
          .catch(error => {
            if(error.response?.status === 404){
              commit(RESET_VISUAL_STATE)
            }
          }).finally(() => {
            resolve()
          })
      })
    },
    [FETCH_PDF_PAGES]: async ({ commit }, payload) => {
      commit(STATE_LOADING, true)
      try {
        const { data } = await api.save(`${payload.component}`, payload.object)
        commit(STATE_LOADING, false)
        if (data.success && data.data) {
          commit(SET_PDF_PAGES, data.data)
        } else {
          commit(RESET_PDF_PAGES)
        }
      } catch (error) {
        commit(STATE_LOADING, false)
        commit(RESET_PDF_PAGES)
      }
    },
    [FETCH_COMMENTS]: async ({ commit }, payload) => {
      try {
        const { data } = await api.findByParams(`${payload.component}`, { params: payload.filter })
        if (data.success && data.data) {
          commit(SET_COMMENTS, data.data)
        } else {
          commit(RESET_COMMENTS)
        }
      } catch (error) {
        commit(RESET_COMMENTS)
      }
    },
  },
}
