import {
  mdiReceipt,
  mdiBookmark,
  mdiDeleteRestore,
  mdiFolderAccount,
} from '@mdi/js'

export default [
  {
    subheader: 'FORM',
  },
  {
    title: 'Template',
    icon: mdiReceipt,
    to: 'form-template-list',
  },
  {
    title: 'Forms',
    icon: mdiReceipt,
    to: 'form-builder-list',
  },
  {
    title: 'Trash',
    icon: mdiDeleteRestore,
    to: 'trash-form-list',
  },
  {
    title: 'Bookmark',
    icon: mdiBookmark,
    to: 'bookmark-form-list',
  },
  {
    title: 'Shared with me',
    icon: mdiFolderAccount,
    to: 'shared-form-list',
  },
]
