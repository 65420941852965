import api from '@/plugins/apiService'

export default {
  namespaced: true,
  state: {
    laborCost: [],
    nonLaborCost: [],
    nonLaborTemplate: [],
    estimateList: [],
    allCalculatorList: []
  },
  getters: {
    GET_LABOR_COST: state => state.laborCost,
    GET_NONLABOR_COST: state => state.nonLaborCost,
    GET_NONLABOR_TEMPLATE: state => state.nonLaborTemplate,
    GET_TEMPLATE_BY_ID: state => id => state.nonLaborTemplate.find(i =>  id == i.uuid),
    GET_NONLABOR_COST_BY_IDS: state => ids => state.nonLaborCost.filter(i => ids.includes(i.uuid)),
    GET_ESTIMATE_LIST: state => state.estimateList,
    GET_ALL_CALCULATOR: state => state.allCalculatorList,
  },
  mutations: {
    SET_LABOR_COST: (state, payload) => {
      state.laborCost = payload
    },
    SET_NONLABOR_COST: (state, payload) => {
      state.nonLaborCost = payload
    },
    SET_NONLABOR_TEMPLATE: (state, payload) => {
      state.nonLaborTemplate = payload
    },
    SET_ESTIMATE_LIST: (state, payload) => {
      state.estimateList = payload
    },
    SET_ALL_CALCULATOR: (state, payload) => {
      state.allCalculatorList = payload
    }
  },
  actions: {
    FETCH_LABOR_COST: async ({ commit }) => {
      const config = {
        limit: -1,
      }
      api.findByParams('setup/materials/labor-cost', { params: config }).then(({ data }) => {
        commit('SET_LABOR_COST', data.data.filter(i => !i.is_non_labor))
        commit('SET_NONLABOR_COST', data.data.filter(i => i.is_non_labor))
      })
    },

    FETCH_NONLABOR_TEMPLATE: async ({ commit }) => {
      const config = {
        limit: -1,
      }
      api.findByParams('/setup/materials/labor-cost-template', { params: config }).then(({ data }) => {
        commit('SET_NONLABOR_TEMPLATE', data.data)
      })
    },

  },
}
