import { render, staticRenderFns } from "./LayoutContentHorizontalNav.vue?vue&type=template&id=3563e960&scoped=true&"
import script from "./LayoutContentHorizontalNav.vue?vue&type=script&lang=js&"
export * from "./LayoutContentHorizontalNav.vue?vue&type=script&lang=js&"
import style0 from "./LayoutContentHorizontalNav.vue?vue&type=style&index=0&id=3563e960&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3563e960",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VApp,VIcon,VMain,VNavigationDrawer,VSystemBar})
